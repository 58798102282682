import { useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";

/**
 * Button for Google Sign in.

 * @return {jsx}
 *
 */
const ButtonGoogle = (props) => {
  const {
    text,
    className,
    id,
    type,
    handleGoogleLogin,
    handleBeforeClick,
    initHandleClick,
  } = props;

  /**
   * Execute current coe from parent component
   */
  const handleClick = () => {
    if (typeof handleBeforeClick === "function") {
      handleBeforeClick();
    }

    handleGoogleClick();
  };

  const handleGoogleClick = useGoogleLogin({
    scope:
      "email profile openid https://www.googleapis.com/auth/userinfo.profile" +
      " https://www.googleapis.com/auth/userinfo.email" +
      " https://www.googleapis.com/auth/user.gender.read" +
      " https://www.googleapis.com/auth/plus.login" +
      " https://www.googleapis.com/auth/user.birthday.read",
    onSuccess: (tokenResponse) => {
      if (tokenResponse.access_token) {
        handleGoogleLogin(null, tokenResponse);
        return;
      }

      handleGoogleLogin(true, null);
    },
    onError: () => {
      handleGoogleLogin(true, null);
    },
  });

  useEffect(() => {
    if (typeof initHandleClick === "function") {
      initHandleClick(handleClick);
    }
  }, []);

  return (
    <button className={className} onClick={handleClick} id={id} type={type}>
      <span> {text}</span>
    </button>
  );
};

export default ButtonGoogle;
