import {
  CONST_REG_TYPE_SIMPLE,
  POST_REG_FUNNEL_SHOW,
  POST_REG_FUNNEL_SHOW_REQUIRED,
  REGISTRATION_CONFIRMED,
  STATUS_SUCCESS,
} from "shared/constants";
import {
  REGISTRATION_COOKIE_NAME,
  REGISTRATION_COOKIE_QUERY_NAME,
} from "shared/constants/cookie";

import { loginEvent, registrationEvent } from "./analytics";
import { deleteCookie, getCookie } from "./cookie";
import { getClientLanguage, getTimeZone } from "./date";

/**
 * Get landing registration data
 * @return {string}
 */
export const getLandQuery = () => {
  let search = window.location.search ? window.location.search : null,
    cookie = getCookie(REGISTRATION_COOKIE_QUERY_NAME),
    href = window.location.href,
    queryList = {};

  if (search) {
    href = href.replace(search, "");
  }

  if (cookie && cookie.length > 0) {
    const params = cookie.split("&");

    if (params && params.length > 0) {
      for (let i = 0; i < params.length; i++) {
        let paramObj = params[i].split("=");

        if (paramObj[0] && paramObj[1]) {
          queryList[paramObj[0]] = paramObj[1];
        }
      }
    }
  }

  if (search) {
    if (search.substring(0, 1) === "?") search = search.substring(1);

    if (search && search.length > 0) {
      let params = search.split("&");

      if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
          let paramObj = params[i].split("=");

          if (paramObj[0] && paramObj[1]) {
            queryList[paramObj[0]] = paramObj[1];
          }
        }
      }
    }
  }

  if (Object.keys(queryList).length > 0) {
    Object.keys(queryList).forEach(function (param, index) {
      if (index <= 0) href += "?";
      else href += "&";

      href += param + "=" + queryList[param];
    });
  }

  return href;
};

/**
 * Prepare registration data before send to api
 * @param {Object} data Form data
 * @return {{birthday, google_id: (null|string|*|string), gender, timezone: string, language: string, truid: (*|string), queries: string, password: (*|string), queryLand: string, first_name: (string|*), reg_source_type: (*|number), email, srcuid: (string|boolean|string)}}
 */
export const prepareRegistrationData = (data) => {
  let trCookie =
      typeof window.simplyTrack !== "undefined" && window.simplyTrack.getTr
        ? window.simplyTrack.getTr()
        : "",
    affCookie = getCookie("srcuid");

  return {
    birthday: data.birthday,
    email: data.email,
    first_name:
      typeof decodeURI === "function"
        ? decodeURI(data.first_name)
        : data.first_name,
    gender: data.gender,
    language: getClientLanguage(),
    password: data.password ? data.password : "",
    queries: window.location.href,
    queryLand: getLandQuery(),
    srcuid: affCookie ? affCookie : "",
    timezone: getTimeZone(),
    truid: trCookie,
    reg_source_type: data.reg_source_type
      ? data.reg_source_type
      : CONST_REG_TYPE_SIMPLE,
    google_id: data.google_id ? data.google_id : "",
  };
};

/**
 * Prepare login request data
 * @param {Object} data Form data
 * @return {{access_token: (*|string), password, google_id: (*|string), timezone: string, truid: (*|string), email}}
 */
export const prepareLoginData = (data) => {
  let trCookie =
    typeof window.simplyTrack !== "undefined" && window.simplyTrack.getTr
      ? window.simplyTrack.getTr()
      : "";

  return {
    email: data.email,
    password: data.password,
    timezone: getTimeZone(),
    truid: trCookie,
    google_id: data.id ? data.id : "",
    access_token: data.access_token ? data.access_token : "",
  };
};

/**
 * Clear Forms
 * @param {string} crossAttr
 * @param {string} bgAttr
 * @param {function} cb
 */
export const clearForm = (crossAttr, bgAttr, cb) => {
  const closeIcon = document.querySelectorAll(crossAttr);
  const modalBgRef = document.querySelectorAll(bgAttr);

  closeIcon.forEach((icon) => {
    icon.addEventListener("click", () => {
      cb();
    });
  });

  modalBgRef.forEach((bg) => {
    bg.addEventListener("click", () => {
      cb();
    });
  });

  closeIcon.forEach((icon) => {
    icon.removeEventListener("click", () => {
      cb();
    });
  });
  modalBgRef.forEach((bg) => {
    bg.removeEventListener("click", () => {
      cb();
    });
  });
};

/**
 * Success login function
 * @param {object} response APi response object
 * @param {function} cbSuccess Success callback
 * @param {function} cbFailed Failed callback
 */
export const afterLoginSuccess = (response, cbSuccess, cbFailed) => {
  if (response?.data && response?.status === STATUS_SUCCESS) {
    cbSuccess();

    const { data } = response;
    let afterLoginUrl = "/app/";

    if (data.data.on_moderation) {
      afterLoginUrl = "/moderation/";
    } else if (
      data.data?.reg_funnel_status === POST_REG_FUNNEL_SHOW ||
      data.data?.reg_funnel_status === POST_REG_FUNNEL_SHOW_REQUIRED
    ) {
      afterLoginUrl = "/wizard/";
    }

    loginEvent({ uid: data.data?.uid, url: afterLoginUrl });

    window.location.href = afterLoginUrl;
    return;
  }

  cbFailed();
};

/**
 * Success registration function
 * @param {object} response APi response object
 * @param {boolean} isGoogle APi response object
 * @param {function} cbSuccess Success callback
 * @param {function} cbFailed Failed callback
 */
export const afterRegistrationSuccess = (
  response,
  isGoogle = false,
  cbSuccess,
  cbFailed
) => {
  if (response.data && response.status === STATUS_SUCCESS) {
    const { data } = response;

    if (data.success === true) {
      cbSuccess();
      deleteCookie(REGISTRATION_COOKIE_QUERY_NAME);
      deleteCookie(REGISTRATION_COOKIE_NAME);

      registrationEvent(data.data, isGoogle);

      if (data.data.onModeration === true) {
        window.location.href = "/moderation/";
      } else {
        if (
          data.data.confirmed === REGISTRATION_CONFIRMED &&
          data.data.access_token
        ) {
          if (
            data.data.reg_funnel_status === POST_REG_FUNNEL_SHOW ||
            data.data.reg_funnel_status === POST_REG_FUNNEL_SHOW_REQUIRED
          ) {
            window.location.href = "/wizard/";
          } else {
            window.location.href = "/app/";
          }
        } else {
          window.location.href = "/confirm/";
        }
      }

      return;
    }
  }

  cbFailed();
};
