/**
 * Core
 * */
import { lazy, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import InputGlobal from "shared/components/Form/InputGlobal";
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "shared/components/SpinLoader";

// import Lottie from "lottie-react";
import { ERROR_STATUS_UNPROCESSABLE } from "shared/constants";

import { clearForm } from "shared/helpers/auth";
import { validateEmail } from "shared/helpers/validation";

import { resetPassword } from "shared/services/auth";

import successAnimation from "assets/shared/lotties/success-mark.json";

const Lottie = lazy(() => import("lottie-react"));

export const RestorePass = () => {
  const [restoreData, setRestoreData] = useState({});
  const [errorEmail, setErrorEmail] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const [successReset, setSuccessReset] = useState(false);
  const [loader, setLoader] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const { t } = useTranslation("auth");
  const recaptchaRef = useRef(null);

  /*
   * Styles for animation wrap
   * */
  const style = {
    height: 86,
    width: 136,
    marginLeft: "auto",
    marginRight: "auto",
  };

  /**
   * Effect for clean Restore Password Modal
   * */
  useEffect(() => {
    clearForm(".close__icon", ".background__modal", () => clearRestoreForm());
  }, []);

  /**
   * Effect for close Restore Password Modal
   * */
  let resetPassModal;
  useEffect(() => {
    if (!resetPassModal) {
      resetPassModal = document.querySelector(".restore__modal");
    }
  }, []);

  /**
   * Clean Restore Password Modal
   * */
  const clearRestoreForm = () => {
    recaptchaRef.current?.reset();
    setErrorEmail(null);
    setCaptchaError(null);
    setResponseError(null);
    setRestoreData({});
    setSuccessReset(false);
    handleCloseSuccessModal();
  };

  /**
   * Handle change Email
   * @param {object} event
   */
  const handleChangeEmail = (event) => {
    setErrorEmail(null);
    setRestoreData({ ...restoreData, email: event?.target?.value });
  };

  /**
   * Handle change Google Captcha
   * @param {string} value recaptcha
   */
  const onCaptchaChange = (value) => {
    setCaptchaError(null);
    setRestoreData({ ...restoreData, reCaptcha: value });
  };

  /**
   * Handler for close Success Modal
   * */
  const handleCloseSuccessModal = () => {
    setSuccessReset(false);
    setRestoreData({});
    if (!resetPassModal) {
      resetPassModal = document.querySelector(".restore__modal");
    }
    if (resetPassModal) {
      resetPassModal.classList.remove("show");
      document.body.style.overflow = "auto";
    }
  };

  /**
   * Handler for step back
   * */
  const handleStepBack = () => {
    const loginModal = document.querySelector(".login__modal");
    const restorePassModal = document.querySelector(".restore__modal");
    restorePassModal.classList.remove("show");
    loginModal.classList.add("show");
    document.body.style.overflow = "hidden";
    clearRestoreForm();
  };

  /**
   * Handler for submit form and request on api Restore password
   * */
  const handleResetPassword = () => {
    const resultEmail = validateEmail(restoreData.email);
    if (!resultEmail.status) {
      setErrorEmail(resultEmail.error);
    }
    if (!restoreData.reCaptcha) {
      setCaptchaError(t("captchaError"));
      return;
    }

    if (resultEmail.status && restoreData.reCaptcha) {
      setLoader(true);
      CbResetPassword(restoreData, () => {
        setLoader(false);
        setSuccessReset(true);
      });
    }
  };

  /**
     * Request Reset Password  on api
     * @param {object} dataReset
     * @param {function|null} cbSuccess

     */
  const CbResetPassword = (dataReset, cbSuccess = null) => {
    resetPassword(dataReset)
      .then(({ data }) => {
        if (data?.success) {
          setLoader(false);
          if (cbSuccess) {
            cbSuccess();
          }
        }
      })
      .catch(({ response }) => {
        setLoader(false);
        recaptchaRef.current?.reset();
        setRestoreData({ ...restoreData, reCaptcha: "" });
        if (response.status === ERROR_STATUS_UNPROCESSABLE) {
          const { data } = response;
          let errorText = "";
          data.errors.forEach((error) => {
            if (error.name === "email") {
              setErrorEmail(error.message);
            } else if (error.name === "reCaptcha") {
              recaptchaRef.current?.reset();
              setCaptchaError(error.message);
            } else {
              errorText +=
                errorText.length > 0 ? " " + error.message : error.message;
            }
          });
          if (errorText.length !== 0) {
            setResponseError(errorText);
          }
        } else {
          setResponseError(t("resetPassRespError"));
        }
      });
  };
  return (
    <>
      {loader && (
        <SpinLoader
          color={LOADER_COLOR_DARK}
          size={LOADER_SIZE_LARGE}
          position={POSITION_ABSOLUTE}
          withBlur={true}
          isFormBlur={true}
        />
      )}
      {successReset ? (
        <>
          <div className="restore__success-body">
            <Lottie
              style={style}
              loop={false}
              animationData={successAnimation}
              autoplay={true}
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
            <span className="title">{t("successModalTitle")}</span>
            <p className="text">{t("passwordSuccessModalText")}</p>
            <button
              onClick={handleCloseSuccessModal}
              className="form__button reset__password"
            >
              {t("okButton")}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="restore_header">
            <span className="title">{t("resetPasswordButton")}</span>
            <p className="text">{t("resetPasswordText")}</p>
          </div>
          <div className="restore__body">
            <span className="label_global">Email</span>
            <InputGlobal
              className={"register__email"}
              handleChange={(e) => handleChangeEmail(e)}
              name="email_restore"
              value={restoreData.email}
              error={!!errorEmail}
              errorText={errorEmail}
              placeholder={t("placeholderEmail")}
            />
            <div className={captchaError ? "recaptcha-error" : ""}>
              <div className="g-recaptcha">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_APP_RECAPTCHA_TOKEN}
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                />
              </div>
              {captchaError && <p className="error captcha">{captchaError}</p>}
            </div>
          </div>
          <div className="restore__footer">
            <button
              onClick={handleResetPassword}
              className="form__button reset__password"
            >
              {t("resetPasswordButton")}
            </button>
            <button className="button__back" onClick={handleStepBack}>
              {t("backButton")}
            </button>
            {responseError && <p className="error response">{responseError}</p>}
            <p className="support__text">{t("haveQuestions")}</p>
            <a
              className="support-mail"
              href={`mailto:${
                import.meta.env.VITE_APP_MULTI_SITE_SUPPORT_EMAIL
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {import.meta.env.VITE_APP_MULTI_SITE_SUPPORT_EMAIL}
            </a>
          </div>
        </>
      )}
    </>
  );
};
