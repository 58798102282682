import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";

import ButtonGoogle from "shared/components/Auth/ButtonGoogle";

import { ERROR_STATUS_UNPROCESSABLE } from "shared/constants";

import { afterLoginSuccess, prepareLoginData } from "shared/helpers/auth";

import { sinInWithGoogle } from "shared/services/auth";

export const GoogleSignIn = ({ setLoader, setResponseError }) => {
  const [baseInfo, setBaseInfo] = useState({ finished: false, data: {} });
  const { t } = useTranslation("auth");

  useEffect(() => {
    if (!baseInfo.finished) {
      return;
    }

    if (!baseInfo.data?.access_token || !baseInfo.data?.id) {
      setResponseError(t("signInGoogleRespError"));
      return;
    }

    setLoader(true);

    sinInWithGoogle(prepareLoginData(baseInfo.data))
      .then((resp) => cbLoginSuccess(resp))
      .catch((err) => responseErrorCb(err));
  }, [baseInfo.data]);

  /**
   * Callback function after received google response data
   *
   * @param {null|boolean} error Success or failed request
   * @param {Object} data Google response data
   */
  const handleGoogleLogin = (error, data) => {
    setBaseInfo({ finished: false, data: {} });

    if (data.access_token) {
      getUserBaseInfo(data.access_token);
      return;
    }
    setResponseError(t("signInGoogleRespError"));
  };

  /**
   * Get user base info data
   * @param {String} accessToken Google access token
   */
  const getUserBaseInfo = (accessToken) => {
    fetch("https://www.googleapis.com/oauth2/v1/userinfo?alt=json", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data) {
              setBaseInfo({
                finished: true,
                data: { ...{ access_token: accessToken }, ...data },
              });
              return;
            }
            setBaseInfo({ finished: true, data: {} });
          })
          .catch(() => setBaseInfo({ finished: true, data: {} }));
      })
      .catch(() => setBaseInfo({ finished: true, data: {} }));
  };

  /**
   * Success api response
   * @param {object} response APi response object
   */
  const cbLoginSuccess = (response) => {
    afterLoginSuccess(
      response,
      () => {
        // setBaseInfo({ finished: true, data: {} });
        setResponseError(null);
      },
      () => setLoader(false),
      true
    );
  };

  /**
   * Failed request on api
   * @param {object} responseErr failed request on api
   */
  const responseErrorCb = (responseErr) => {
    setLoader(false);
    if (responseErr.response.status === ERROR_STATUS_UNPROCESSABLE) {
      const { data } = responseErr.response;
      let errorText = "";

      data.errors.forEach((error) => {
        errorText += error > 0 ? " " + error.message : error.message;
      });

      if (errorText !== "") {
        setResponseError(errorText);
      }
    } else {
      setResponseError(t("signInGoogleRespError"));
    }
  };

  return (
    <>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_APP_GOOGLE_AUTH_CLIENT_ID}
      >
        <ButtonGoogle
          className={"button__google sign__in"}
          text={"Sign in via Google"}
          handleGoogleLogin={handleGoogleLogin}
        />
      </GoogleOAuthProvider>
    </>
  );
};
